
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.features') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="tableItems",
        ref="companyFeaturesTable",
        :disablePagination="true"
      )
        template(slot="cel_is_enabled", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.is_enabled",
            switch,
            @change="changeEnabled(item)"
          )
        template(slot="cel_settings", slot-scope="{ item }")
          template(v-if="item.value === 'dimensions_dependence'")
            div
              b-form-group(
                :label="$t(`companies.features.dimension_number`, {number: 1})",
                :invalid-feedback="errors.first('dimension_1')"
                :state="errors.has('dimension_1') ? false : null"
                label-cols="4"
              )
                company-dimension-names-select(
                  :companyId="$route.params.id"
                  v-model="item.settings.dimension_1"
                  v-validate="{required: item.is_enabled}"
                  data-vv-name="dimension_1"
                  :data-vv-as="$t(`companies.features.dimension_number`, {number: 1})",
                  :disabled="!item.is_enabled"
                  @input="changeDimension"
                )
              b-form-group(
                :label="$t(`companies.features.dimension_number`, {number: 2})",
                :invalid-feedback="errors.first('dimension_2')"
                :state="errors.has('dimension_2') ? false : null"
                label-cols="4"
              )
                company-dimension-names-select(
                  :companyId="$route.params.id"
                  v-model="item.settings.dimension_2"
                  v-validate="{required: item.is_enabled}"
                  data-vv-name="dimension_2"
                  :disabled="!item.is_enabled"
                  :data-vv-as="$t(`companies.features.dimension_number`, {number: 2})",
                  @input="changeDimension"
                )
              .d-flex.justify-content-end(v-if="dimensionFeatureChanged && item.is_enabled")
                b-btn(
                  variant="primary"
                  squared
                  :disabled="dimensionFeatureInProgress"
                  @click="saveDimensions"
                ) {{ $t('actions.save') }}
          template(v-else-if="Object.entries(item.settings).length !== 0")
            b-form-checkbox(
              v-for="(value, name) in item.settings",
              :key="name",
              v-if="['cost_centers_set', 'prioritize_keywords_methods', 'prioritize_vendors_methods'].includes(name)",
              v-model="item.settings[name]",
              switch,
              @change="changeEnabled(item)"
            ) {{ $t(`companies.features.${name}`) }}
          template(v-else)
            p None
        template(slot="cel_actions", slot-scope="{ item }")
          stack(spacing="xs")
            stack-item
              b-btn(
                size="xs",
                variant="flat-danger",
                squared,
                @click="deleteReport(item)",
              )
                .mdi.mdi-trash-can-outline
                | {{ $t('actions.delete') }}
      .p(v-if="automationFeatureChanged")
        div.d-flex.justify-content-center
          div.alert.alert-warning
            .mdi.mdi-alert-box
              | {{ $t(`companies.features.automation_feature_reminder`) }}
        div.d-flex.justify-content-center
          stack
            stack-item(fill)
              b-form-group(
                label-for="bic-input",
                label-cols="0",
                :invalid-feedback="errors.first('comment')"
                :state="errors.has('comment') ? false : null"
              )
                b-input(
                  v-model="invoiceGuesserFeature.comment",
                  v-validate="'required'"
                  data-vv-name="comment"
                  :data-vv-as="$t('companies.features.changes_comment')"
                  placeholder="Changes comment"
                )
            stack-item
              b-btn(variant="primary", squared, @click="changeSettings(null)")
                .mdi.mdi-account-check-outline
                | {{ $t('actions.save') }}

      .limited-with.mt-4
        h5 {{ $t('companies.features.settings') }}
        b-form-group(
          :label="$t(`companies.features.automate_accounting`)",
          label-for="automate-accounting-input",
          label-cols="4"
        )
          b-form-checkbox#automate-accounting-input(
            v-model="company.automate_accounting",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.deduct_vat`)",
          label-for="deduct-vat-input",
          label-cols="4"
        )
          b-form-checkbox#deduct-vat-input(
            v-model="company.deduct_vat",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.overwrite_suggestions`)",
          label-for="overwrite-suggestions-input",
          label-cols="4"
        )
          b-form-checkbox#overwrite-suggestions-input(
            v-model="company.overwrite_suggestions",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.voucher_date`)",
          label-for="voucher-date-input",
          label-cols="4"
        )
          b-form-checkbox#voucher-date-input(
            v-model="invoiceGuesserFeature.settings.voucher_date",
            switch
          )
        b-form-group(
          :label="$t('companies.features.comment_user')",
          label-for="comment-user-input",
          label-cols="4"
        )
          b-input#comment-user-input(
            v-model="invoiceGuesserFeature.comment_user",
          )
        b-form-group(
          :label="$t('companies.basic.iban')",
          label-for="iban-input",
          label-cols="4"
        )
          b-input#iban-input(v-model="company.iban")

        b-form-group(
          :label="$t('companies.basic.bic')",
          label-for="bic-input",
          label-cols="4"
        )
          b-input#bic-input(v-model="company.bic")

      .mt-4(v-if="showScope")
        h5 {{$t('companies.features.scope_invoice_number_starting_symbols')}}
        data-table(
          :headers="invoicesStartingSymbolsHeaders",
          :items="invoicesStartingSymbols",
          disablePagination
        )
          template(#cel_symbol="{item, index}")
            b-form-input(
              :value="invoicesStartingSymbols[index].symbol"
              @input="updateSymbol(index, $event)"
              maxlength="1"
              v-validate="'required'"
              :name="`symbol.${index}.symbol`"
              :state="errors.first(`symbol.${index}.symbol`) ? false : null"
            )
          template(#cel_count="{item, index}")
            b-form-input(
              v-model.number="invoicesStartingSymbols[index].count"
              step="1"
              type="number"
              @keypress="strictNumberInput($event)"
              v-validate="'required'"
              :name="`symbol.${index}.number`"
              :state="errors.first(`symbol.${index}.number`) ? false : null"
            )
          template(#cel_actions="{index}")
            b-btn(
              variant="flat-danger"
              squared
              size="xs"
              @click="deleteSymbol(index)"
            ) 
              .mdi.mdi-trash-can-outline
              | {{ $t('actions.delete') }}
          template(#empty_title) 
            div
          template(#empty_description)
            div
              p {{$t('companies.features.no_symbols')}}
              p
                b-btn.add-symbol-button(
                  variant="flat-primary"
                  squared
                  size="xs"
                  @click="addSymbol()"
                )
                  .mdi.mdi-plus
                  | {{$t('companies.features.add_symbol')}}
        b-btn.add-symbol-button(
          v-if="invoicesStartingSymbols.length"
          variant="flat-primary"
          squared
          size="xs"
          @click="addSymbol()"
        )
          .mdi.mdi-plus
          | {{$t('companies.features.add_symbol')}}

      .mt-4
        h5 {{$t('companies.features.scope_sales_invoices_print_codes')}}
        data-table(
          :headers="scopePrintCodesHeaders",
          :items="scopePrintCodes",
          disablePagination
        )
          template(#cel_key="{item, index}")
            b-form-input(
              :value="scopePrintCodes[index].key"
              @input="scopePrintCodes[index].key = clearPrintCodeKey($event)"
              v-validate="{ required: true, scope_print_codes_duplicate: { list: scopePrintCodes } }"
              maxlength="10"
              :name="`scope_print_code.${index}.key`"
              :state="errors.first(`scope_print_code.${index}.key`) ? false : null"
            )
          template(v-for="language in scopePrintCodesLanguages" #[`cel_${language}`]="{item, index}")
            b-form-textarea(
              v-model="scopePrintCodes[index][language]"
              v-validate="'required'"
              maxlength="512"
              :name="`scope_print_code.${index}.${language}`"
              :state="errors.first(`scope_print_code.${index}.${language}`) ? false : null"
            )
          template(#cel_actions="{index}")
            b-btn(
              variant="flat-danger"
              squared
              size="xs"
              @click="deleteScopePrintCode(index)"
            ) 
              .mdi.mdi-trash-can-outline
              | {{ $t('actions.delete') }}
          template(#empty_title) 
            div
          template(#empty_description)
            div
              p {{$t('companies.features.no_symbols')}}
              p
                b-btn.add-symbol-button(
                  variant="flat-primary"
                  squared
                  size="xs"
                  @click="addScopePrintCode()"
                )
                  .mdi.mdi-plus
                  | {{$t('companies.features.add_print_code')}}
        b-btn.add-symbol-button(
          v-if="scopePrintCodes.length"
          variant="flat-primary"
          squared
          size="xs"
          @click="addScopePrintCode()"
        )
          .mdi.mdi-plus
          | {{$t('companies.features.add_print_code')}}

      .mt-4
        b-btn(variant="primary", squared, @click="changeSettings(null)")
          .mdi.mdi-account-check-outline
          | {{ $t('actions.save') }}
    
